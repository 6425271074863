<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">
        Stock Adjustment - {{ id > 0 ? 'Edit' : 'Tambah' }}
      </span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">
          / Inventory / Stock Adjustment / {{ id > 0 ? 'Edit' : 'Tambah' }}
        </span>
      </div>
    </div>

    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSave"
            icon="pi pi-save"
            class="mr-2"
            :label="id > 0 ? 'Update' : 'Simpan'"
            :disabled="saved || old_status_value === 'done'"
            type="submit"
          />
          <Button
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Item"
            :disabled="saved || old_status_value === 'done'"
            @click="onAddData"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'StockAdjustment' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-8">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label>Tgl. Adjustment</label>
                <Calendar
                  v-model="form.sop_at"
                  dateFormat="dd/mm/yy"
                  class="w-full mr-2"
                  disabled
                  input-class="w-full"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>No Adjustment</label>
                <InputText
                  v-model="form.sop_no"
                  class="w-full"
                  input-class="w-full"
                  maxlength="50"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Lokasi/Gudang</label>
                <InputText
                  v-model="form.whouse"
                  class="w-full"
                  input-class="w-full"
                  maxlength="100"
                  :disabled="saved || old_status_value === 'done'"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Dicek/Dihitung oleh:</label>
                <InputText
                  v-model="form.assignee"
                  class="w-full"
                  input-class="w-full"
                  maxlength="255"
                  :disabled="saved || old_status_value === 'done'"
                />
              </div>
              <div class="field col-12 md:col-8">
                <label>Deskripsi</label>
                <InputText
                  v-model="form.description"
                  class="w-full"
                  input-class="w-full"
                  maxlength="100"
                  :disabled="saved || old_status_value === 'done'"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Status</label>
                <Dropdown
                  v-model="form.status"
                  :options="list_status"
                  :disabled="!id || old_status_value === 'done'"
                  optionValue="value"
                  optionLabel="label"
                  optionDisabled="disabled"
                  class="w-full"
                  input-class="w-full"
                />
                <small v-if="item_id == 0 && submitted" class="p-error"
                  >Item harus dipilih.</small
                >
              </div>
              <div
                v-if="form.id > 0 && form.status == 'done'"
                class="field col-12 md:col-4"
              >
                <label>Disetujui Oleh</label>
                <InputText
                  v-model="form.inspector"
                  class="w-full"
                  input-class="w-full"
                  maxlength="100"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <fx-table
            :items="form.details"
            :total="form.details.length"
            :loading="isLoading"
            :paginator="false"
            showGridlines
          >
            <template #columns>
              <Column field="item_sku" header="SKU" style="width: 10%" />
              <Column field="item_name" header="Nama Item" style="width: 30%" />
              <Column
                field="unit_s"
                header="Satuan"
                style="width: 19%; justify-content: flex-end"
              />
              <Column
                field="qty_noted"
                header="QTY Tercatat"
                style="width: 13%; justify-content: flex-end"
              >
                <template #body="slotProps">
                  {{ slotProps.data.qty_noted }} 
                </template>
              </Column>
              <Column
                field="qty"
                header="qty Hitung"
                style="width: 13%; justify-content: flex-end"
              >
                <template #body="slotProps">
                  {{ slotProps.data.qty }} 
                </template>
              </Column>
              <Column style="flex-grow: 1; justify-content: flex-end">
                <template #body="{ data }">
                  <Button
                    type="button"
                    icon="pi pi-pencil"
                    class="
                      p-button-outlined p-button-secondary p-button-text
                      mr-2
                    "
                    :disabled="saved || old_status_value === 'done'"
                    @click="onEditData(data)"
                  />
                  <Button
                    type="button"
                    icon="pi pi-trash"
                    class="p-button-outlined p-button-danger p-button-text"
                    :disabled="saved || old_status_value === 'done'"
                    @click="onConfirmDeletion(data)"
                  />
                </template>
              </Column>
            </template>
          </fx-table>
        </div>
      </div>
    </form>
    <Dialog
      :header="itemdialogtext"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-adjustment
        :item="formDetail"
        :loading="isLoadingSave"
        :adjustment="Adjustment"
        :editMode="editMode"
        :opnameMode="false"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Stock Adjustment"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item Stock Adjustment
          <strong>{{ formDetail.item_name }}</strong> akan dihapus. Proses?
        </span>
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItemAdjustment"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import StockOpnameService from '@/services/StockOpnameService'
import FormAdjustment from '@/components/inventory/FormAdjustment'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import useVuelidate from '@vuelidate/core'
import { useAuthStore } from '@/store/auth'
import dayjs from 'dayjs'
export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'Adjustment',
  components: {
    FormAdjustment,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      submitted: false,
      saved: false,
      stockOpnameService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      editMode: false,
      old_status_value: 'draft',
      items: [],
      itemdialogtext: '',
      form: {
        sop_at: new Date(),
        sop_type: 'adjustment',
        inspector: '',
        assignee: '',
        whouse: '',
        description: '',
        status: 'draft',
        details: [],
      },
      formDetail: {
        item_id: 0,
        item_name: '',
        item_sku: '',
        qty_noted: 0,
        unit_b: 0,
        unit_s: 0,
        ubox: 0,
        qty_b: 0,
        qty_s: 0,
        qty: 0,
      },
      isCheck: false,
      listDeleted: [],
      Adjustment: [],
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  computed: {
    list_status() {
      const list = [
        { value: 'draft', label: 'Draft', level: 0 },
        { value: 'done', label: 'Done', level: 1 },
      ]
      let old_status_level = 0
      if (this.old_status_value) {
        const status = list.find((e) => e.value === this.old_status_value)
        if (status) old_status_level = status.level
      }
      return list.map((status) => ({
        ...status,
        disabled: old_status_level > status.level,
      }))
    },
  },
  created() {
    this.stockOpnameService = new StockOpnameService()
  },
  async mounted() {
    const auth = useAuthStore()
    this.form.inspector = auth.user.name

    if (this.id > 0) {
      this.loadData()
    }
  },
  methods: {
    setDateJthTempo() {
      const idx = this.list_items.findIndex(
        (item) => item.id === this.form.term_id
      )
      if (idx != -1) {
        const sop_at = dayjs(this.form.sop_at)
        const due_at = sop_at.add(this.list_term[idx].term, 'day')
        this.form.due_at = new Date(due_at)
      }
    },
    totalDetail(detail) {
      const item =
        this.list_items.filter((item) => item.id === detail.item_id).length > 0
          ? this.list_items.filter((item) => item.id === detail.item_id)[0]
          : {}

      // perhitungan berdasarkan PER
      let qty_b = detail['qty_b']
      let qty_s = detail['qty_s']
      let b, qty, bruto

      if (detail['cost_per'] === detail['unit_b']) {
        b = detail['ubox'] * qty_b
        qty = b + qty_s
        bruto = (detail['cost'] / detail['ubox']) * qty
      } else {
        qty = qty_b + qty_s
        bruto = detail['cost'] * qty
      }

      // total
      let dpp = bruto

      // disc ext
      let disc_ext = detail['disc_ext']
      if (disc_ext >= 1.0 && disc_ext <= 100.0) {
        let disc_ext_value = dpp * (disc_ext / 100.0)
        dpp -= Math.round(disc_ext_value * 100) / 100
      } else if (disc_ext > 100.0) {
        let disc_ext_value = disc_ext
        dpp -= Math.round(disc_ext_value * 100) / 100
      }

      // // ppn
      // let ppn = Math.round(dpp * (detail.tax / 100.0) * 100) / 100

      // // total
      // let total = dpp + ppn
      let total = dpp

      // price
      let price = dpp / qty / (1 - item.std_mrg / 100.0)
      price = Math.round((price * (1 + (this.form.tax / 100.0) * 100)) / 100)

      return {
        qty: qty,
        bruto: bruto,
        dpp: dpp,
        // ppn: ppn,
        total: total,
        price: price,
      }
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const deleted = this.id ? { deleted: this.listDeleted } : {}

      const sop_at = this.form.sop_at
        ? { sop_at: dayjs(this.form.sop_at).format('YYYY-MM-DD') }
        : {}
      const form = {
        ...sop_at,
        status: this.form.status,
        sop_no: this.form.sop_no,
        sop_type: 'adjustment',
        inspector: this.form.inspector,
        assignee: this.form.assignee,
        whouse: this.form.whouse,
        description: this.form.description,
        details: this.form.details.map((item) => {
          const id = item.id ? { id: item.id } : this.id ? { id: 0 } : {}
          return {
            ...id,
            item_id: item.item_id,
            qty_noted: item.qty_noted,
            qty_b: item.qty_b,
            qty_s: item.qty_s,
          }
        }),
        ...deleted,
      }

      if (!this.id) {
        this.isLoadingSave = true
        this.stockOpnameService
          .add(form)
          .then((res) => {
            if (res.data.status === 1) {
              this.$toast.add({
                severity: 'success',
                summary: 'Stock Adjustment',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })

              this.$router.push({ name: 'StockAdjustment' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Stock Adjustment', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSave = false
          })
      } else {
        this.isLoadingSave = true
        this.stockOpnameService
          .update(this.id, form)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Stock Adjustment',
                detail: 'Data berhasil diupdate.',
                life: 3000,
              })
              if (form.status == 'done') {
                this.saved = true
                this.$router.push({ name: 'StockAdjustment' })
              }
            }
          })
          .catch((err) => {
            errorHandler(err, 'Stock Adjustment', this)
          })
          .finally(() => {
            this.isLoadingSave = false
          })
      }
    },
    loadData() {
      this.isLoading = true
      this.stockOpnameService
        .getById(this.id)
        .then((res) => {
          this.form = res.data.data
          this.old_status_value = this.form.status
          this.form.sop_at = this.form.sop_at
            ? new Date(this.form.sop_at)
            : new Date()
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Stock Adjustment', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.itemdialogtext = 'Edit Item Stock Adjustment'
      this.editMode = true
      this.formDetail = Object.assign({}, data)
      this.dialog = true
    },
    onAddData() {
      this.itemdialogtext = 'Add Item Stock Adjustment'
      this.editMode = false
      this.formDetail = {
        item_id: '',
        unit_b: '',
        unit_s: '',
        cost_per: '',
        ubox: '',
        qty_b: 1,
        qty_s: 0,
        qty: 0,
        cost: 0,
        disc_reg: 0,
        disc_ext: 0,
      }
      this.dialog = true
    },
    async onSaveData(data) {
      const detailItem = Object.assign({}, data)

      const idx = this.form.details.findIndex(
        (item) => item.item_id === detailItem.item_id
      )
      if (this.editMode) {
        Object.assign(this.form.details[idx], { ...detailItem })
        this.dialog = false
      } else {
        if (idx === -1) {
          this.form.details.push(detailItem)
          this.$toast.add({
            severity: 'success',
            summary: 'Stock Adjusment',
            detail: 'Data Item berhasil ditambahkan.',
            life: 3000,
          })
          this.dialog = false
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Stock Adjusment',
            detail: 'Item ' + detailItem.item_name + ' sudah ada',
            life: 3000,
          })
        }
      }
    },
    onConfirmDeletion(data) {
      this.formDetail = data
      this.dialogHapus = true
    },
    onDeleteItemAdjustment() {
      const index = this.form.details.findIndex(
        (el) => el.item_id === this.formDetail.item_id
      )
      if (index !== -1) {
        if (this.id && this.form.details[index].id) {
          this.listDeleted.push(this.form.details[index].id)
        }
        this.form.details.splice(index, 1)
        this.$toast.add({
          severity: 'success',
          summary: 'Data Item Stock Adjustment',
          detail: 'Data berhasil dihapus.',
          life: 3000,
        })
      }
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
  validations() {
    return {}
  },
}
</script>
