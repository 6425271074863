<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <!-- <div v-if="opnameMode" class="field col-5 md:col-5">
              <label>Waktu Hitung</label>
              <Calendar
                v-model="form.sop_at"
                showIcon
                iconDisplay="input"
                timeOnly
              />
            </div> -->
            <div class="field col-9 md:col-9">
              <label>Item</label>
              <AutoComplete
                v-model="selectedItem"
                class="w-full"
                input-class="w-full"
                :suggestions="listItemStocks"
                field="item_name"
                v-on:clear="true"
                @complete="searchItem"
                @item-select="selectItem"
                :disabled="editMode"
              >
                <template #item="slotProps">
                  <div>
                    <b>{{ slotProps.item.item_sku }}</b>
                  </div>
                  <div>{{ slotProps.item.item_name }}</div>
                </template>
              </AutoComplete>
              <small
                v-if="
                  (v$.form.item_id.$invalid && submitted) ||
                  v$.form.item_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.item_id.required.$message }}</small
              >
            </div>
            <div class="field col-3 md:col-3">
              <label>Satuan</label>
              <InputText
                v-model="form.unit_s"
                class="w-full"
                disabled
                input-class="w-full"
              />
            </div>
            <div class="field col-6 md:col-6">
              <label>Qty Tercatat</label>
              <InputText
                v-model="form.qty_noted"
                class="w-full"
                disabled
                input-class="w-full"
              />
            </div>
            <div class="field col-6 md:col-6">
              <label>Qty Hitung</label>
              <InputNumber
                v-model="form.qty_s"
                :class="{ 'p-invalid': v$.form.qty_s.$invalid && submitted }"
                class="w-full"
                :min="0"
                locale="id-ID"
                input-class="w-full"
                :autofocus="
                  (onMenu === 'receive' || onMenu === 'retur') && editMode
                "
              />
              <small
                v-if="
                  (v$.form.qty_s.$invalid && submitted) ||
                  v$.form.qty_s.$pending.$response
                "
                class="p-error"
              >
                <span v-if="v$.form.qty_s.required.$invalid">
                  {{ v$.form.qty_s.required.$message }}
                </span>
                <span v-else-if="v$.form.qty_s.minValue.$invalid"> {{ v$.form.qty_s.minValue.$message }}</span>
                <span v-else-if="v$.form.qty_s.notSameAs.$invalid"> {{ v$.form.qty_s.notSameAs.$message }}</span>
                <span v-if="form.qty > 999999">
                  {{ "nilai maksimal 999.999" }}
                </span>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          :label="editMode ? 'Ubah' : 'Tambahkan'"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import StockService from '@/services/StockService'
import { helpers, required, minValue, maxValue } from '@vuelidate/validators'
import errorHandler from '@/helpers/error-handler'
import AutoComplete from 'primevue/autocomplete'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    AutoComplete,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    opnameMode: {
      type: Boolean,
      default: false,
    },
    onMenu: {
      type: String,
      default: 'order',
    },
  },
  data() {
    return {
      submitted: false,
      stockService: null,
      listItemStocks: [],
      selectedItem: null,
      query: '',
    }
  },
  created() {
    this.stockService = new StockService()
  },
  async mounted() {
    this.selectedItem = this.form.item_name
    this.stockService
      .get()
      .then((res) => {
        this.listItemStocks = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Stock', this)
      })
      .finally(() => (this.isLoading = false))
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      this.form.qty = this.form.qty_b * this.form.ubox
      this.form.qty = this.form.qty + this.form.qty_s

      if (this.form.qty > 999999)
      {
        return
      }

      if (!isFormValid) {
        return
      }
      this.$emit('save', { ...this.form })
    },
    inputSatuan(event) {
      const idx = this.listSatuan.findIndex((item) => item.sat === event.value)
      if (idx !== -1) this.form.ubox = this.listSatuan[idx].qty
    },
    selectItem() {
      this.form.item_id = this.selectedItem.item_id
      this.form.item_name = this.selectedItem.item_name
      this.form.item_sku = this.selectedItem.item_sku
      this.form.item_barcode = this.selectedItem.item_barcode
      this.form.qty_noted = this.selectedItem.qty
      this.form.unit_b = this.selectedItem.unit_b
      this.form.unit_s = this.selectedItem.unit_s
      this.form.ubox = this.selectedItem.ubox
      this.form.qty_b = 0
      this.form.qty_s = this.selectedItem.qty
      this.form.qty = this.selectedItem.qty
    },
    searchItem(event) {
      this.stockService
        .get(event.query)
        .then((res) => {
          this.listItemStocks = res.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Stock', this)
        })
        .finally(() => (this.isLoading = false))
    },
  },
  validations() {
    return {
      form: {
        item_id: {
          required: helpers.withMessage('Items harus diisi.', required),
        },
        qty_s: {
          required: helpers.withMessage('QTY harus diisi.', required),
          minValue: helpers.withMessage(
            'QTY harus sama atau lebih dari 0.',
            minValue(0)
          ),
          notSameAs: helpers.withMessage(
            'QTY terhitung tidak boleh sama dengan QTY tercatat',
            () => {
              return this.form.qty_s !== this.item.qty_noted
            }
          ),
          maxValue: helpers.withMessage(
            'QTY maksimal 999.999.',
            maxValue(999999))
        },
      },
    }
  },
}
</script>
